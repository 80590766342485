module.exports = {
    "dashboard": "ダッシュボード",
    "myAccounts": "私のクレジット",
    "internationalTransfer": "国際移籍",
    "fundingType": "カード＆アカウント",
    "deposit": "クレジット購入",
    "withdraw": "脱退",
    "invoice": "インボイス",
    "requestMoney": "リクエスト・マネー",
    "recipients": "受取人 ",
    "developers": "開発者 ",
    "myProfile": "マイプロフィール",
    "logout": "ログアウト",
    "qucikBooks": "クイックブックス ",
    "profileDetails": "プロフィール詳細",
    "password": "パスワード",
    "users": "ユーザー",
    "qbDownloads": "QBダウンロード",
    "transfer": "移籍",
    "allTransactions": "取引内容",
    "recurringTrans": "定期取引",
    "firstName": "ファーストネーム",
    "lastName": "ラストネーム",
    "emailAddress": "メールアドレス",
    "userRole": "ユーザーの役割",
    "settings": "設定",
    "oldPass": "旧パスワード",
    "newPass": "新しいパスワード",
    "confirmPass": "パスワードの確認",
    "passwordRequirements": "パスワードの条件",
    "reqOne": "8文字以上",
    "reqTwo": "小文字1文字以上",
    "reqThree": "大文字1文字以上",
    "reqFour": "最低1つの数字",
    "reqFive": "最低1つの特殊文字",
    "reqSix": "最低1つの特殊文字",
    "reqSeven": "スペース無し",
    "saveChanges": "変更を保存する",
    "onlyIntAccounts": "海外口座のみ",
    "availableBalance": "利用可能残高",
    "currentBalance": "現在の残高",
    "lastUpdatedDate": "最終更新日",
    "channel": "チャンネル",
    "webhook": "ウェブフック",
    "recipientName": "受取人名",
    "aCHAccountNumber": "ACH口座番号",
    "wireAccountNumber": "電信送金口座番号",
    "aPIKey": "APIキー",
    "clientName": "お客様名",
    "createdBy": "作成者",
    "transactionID": "トランザクションID",
    "sourceType": "ソース・タイプ",
    "accountReference": "口座照会",
    "routingReference": "ルーティング・リファレンス",
    "expiryDate": "有効期限",
    "recipientEmail": "受信者メールアドレス",
    "creationDate": "作成日",
    "startDate": "開始日",
    "reference": "参考",
    "transactionType": "トランザクション・タイプ",
    "name": "名前",
    "phone": "電話",
    "role": "役職",
    "complete": "完了",
    "failure": "失敗",
    "returns": "返品",
    "count": "回数",
    "accounts": "アカウント",
    "noAccounts": "口座なし",
    "transactionsType": "取引タイプ",
    "mostRecentTransactions": "直近の取引",
    "export": "エクスポート",
    "transactionHistory": "取引履歴",
    "transactionReference": "取引参照",
    "parentReference": "保護者参照",
    "paymentReference": "支払照会",
    "amount": "金額",
    "invoiceNumber": "インボイス番号",
    "feeAmount": "手数料",
    "taxAmount": "税額",
    "exciseTax": "物品税",
    "salesTax": "売上税",
    "registrationFee": "登録料",
    "serviceFee": "サービス料",
    "conversionRate": "コンバージョン率",
    "oFACMatch": "OFACマッチ",
    "type": "タイプ",
    "recurringReference": "繰り返し参照",
    "status": "ステータス",
    "created": "作成",
    "account": "アカウント",
    "receiver": "受信機",
    "description": "説明",
    "paymentType": "支払方法",
    "from": "から",
    "sendVia": "経由で送信",
    "recipientEmailAddress": "受信者メールアドレス",
    "recipientPhoneNumber": "受信者電話番号",
    "recipientAccountNumber": "受取人口座番号",
    "confirmAccountNumber": "口座番号の確認",
    "to": "へ",
    "currency": "通貨",
    "direction": "方向",
    "paymentDate": "支払日",
    "service": "サービス",
    "frequency": "頻度",
    "endDate": "終了日",
    "numberofTransfers": "転送回数",
    "internalDescription": "内部説明",
    "accountNumber": "口座番号",
    "fileName": "ファイル名",
    "recipient_email": "受信者メールアドレス",
    "createdDate": "作成日",
    "dueDate": "期日",
    "memo": "メモ",
    "action": "アクション",
    "email": "Eメール",
    "phoneNumber": "電話番号",
    "bankName": "銀行名",
    "recipientType": "受取人のタイプ",
    "fromAccountNumber": "口座番号から",
    "nameonCard": "カード名義",
    "zipCode": "郵便番号",
    "accountName": "口座名",
    "routingNumber": "振込先番号",
    "accountType": "口座種別",
    "address": "住所",
    "city": "市",
    "country": "国名",
    "state": "州",
    "verificationType": "検証タイプ",
    "debitAmount": "引落金額",
    "firstCreditAmount": "最初のクレジット額",
    "secondCreditAmount": "2回目のクレジット額",
    "nickName": "ニックネーム",
    "beneficiaryBankCode": "銀行コード",
    "addressLine1": "住所1行目",
    "addressLine2": "住所2行目",
    "paymentMethod": "支払方法",
    "referenceID": "参照ID",
    "aCHDetails": "ACH詳細",
    "wireDetails": "ワイヤー詳細",
    "internationalTransferDetails": "国際送金の詳細",
    "selectDate": "日付を選択",
    "sender": "送信者",
    "remarks": "備考",
    "buyorSell": "購入または売却",
    "toAccountNumber": "口座番号へ",
    "paymentInstrument": "支払手段",
    "fromaccount": "口座から",
    "toCounterparty": "相手先へ",
    "purposeofPayment": "支払目的",
    "fXRate": "為替レート",
    "fXAmount": "為替金額",
    "fXCurrency": "為替通貨",
    "toName": "名前",
    "toAccount": "口座",
    "nextTransactionDate": "次回取引日",
    "aPICode": "APIコード",
    "publicKey": "公開キー",
    "privateKey": "プライベートキー",
    "optional": "任意",
    "additionalInformation": "追加情報",
    "createPayment": "お支払いの作成",
    "downloadPDF": "PDFダウンロード",
    "resend": "再送",
    "approve": "承認",
    "decline": "減少",
    "addCard": "カードの追加",
    "addBankAccount": "銀行口座の追加",
    "addNewRecipient": "新しい受取人の追加t",
    "synchronizeQuickBooks": "クイックブックの同期",
    "create": "作成",
    "newUser": "新規ユーザー",
    "generate": "生成する",
    "wITHDRAW": "撤回する ",
    "confirm": "確認 ",
    "cancel": "取消",
    "close": "閉じる ",
    "sendResetInstructions": "リセットの指示を送る ",
    "logIn": "ログイン ",
    "resetPassword": "パスワードのリセット ",
    "setPassword": "パスワードの設定 ",
    "signUp": "会員登録",
    "forgotPassword": "パスワードをお忘れの方",
    "backToLogin": "ログインに戻る",
    "enterOtp": "認証コードの入力",
    "resendCode": "新しいコードを送信する",
    "selectPayment": "支払い方法の選択",
    "scheduleRecurringTransaction": "定期取引のスケジュール",
    "forgotPassText": "アカウントに関連付けられたEメールを入力すると、パスワードをリセットするための手順が記載されたEメールが送信されます。",
    "unableToCP": "支払いを作成できない",
    "plsSelectPaymentTyp": "お支払い方法を選択してください",
    "plsSelectFromAcc": "アカウントからお選びください",
    "selectPaymentPayment": "お支払い方法を選択してください",
    "enterEmail": "メールアドレスを入力してください。.",
    "emailValidation": "メールアドレスは320文字を超えて入力することはできません。",
    "enterPhone": "有効な電話番号を入力してください",
    "enterAccNum": "口座番号を入力してください",
    "AccNum": "口座番号を入力してください",
    "selectRecipientName": "受取人名を選択してください",
    "enterAmounttoTransfer": "送金金額を入力してください",
    "enterCurrency": "送金する通貨を入力してください",
    "selectDirection": "送金方向を選択してください",
    "selectPaymentDate": "支払日を選択してください",
    "selectPaymentdate": "支払日を選択してください",
    "selectServiceType": "サービスタイプを選択してください",
    "selectFrequency": "頻度を選択してください",
    "enterAmount": "金額を入力してください",
    "selectTxnDate": "取引日を選択してください",
    "selectRecipientType": "受取人のタイプを選択してください",
    "enterEmailAddress": "Eメールアドレスを入力してください",
    "validEmail": "有効なメールアドレスを入力してください",
    "EmailValidation": "メールアドレスは320文字を超えることはできません",
    "selectFrmAcc": "口座から選択してください",
    "invoiceAlpha": "請求書番号は半角英数字で入力してください",
    "enterInvoiceNum": "請求書番号を入力してください",
    "invoiceValidation": "インボイス番号は150文字を超えることはできません",
    "amountToTransfer": "振込金額を入力してください",
    "valueForMemo": "メモを入力してください",
    "memoValidation": "メモには255文字以上の入力はできません",
    "selectPaymentType": "支払タイプを選択してください",
    "enterName": "名前を入力してください",
    "ValidName": "有効な名前を入力してください",
    "nameValidation": "名前は3文字以下では入力できません",
    "Name": "名前を入力してください",
    "validname": "有効な名前を入力してください",
    "nameValid": "お名前は3文字以下で入力できません",
    "enterZipCode": "郵便番号を入力してください。",
    "validZipCode": "有効な郵便番号を入力してください",
    "validzipcode": "郵便番号は5桁以下にはできません。",
    "validAccName": "アカウント名を入力してください。",
    "enterValidAccName": "有効なアカウント名を入力してください",
    "maxAccName": "アカウント名は20桁を超えることはできません。",
    "minAccName": "アカウント名は3桁以下にはできません。",
    "accNum": "口座番号を入力してください。",
    "validAccNum": "有効な口座番号を入力してください。",
    "maxAccNum": "口座番号は20桁を超えることはできません。",
    "japanMaxAccNum": "A口座番号は35桁を超えることはできません。",
    "japanMaxBankName": "銀行名は35桁を超えることはできません。",
    "japanMinSwiftCode": "スイフトコードは8文字以上",
    "japanMinAddress": "住所は2文字以下にはできません。",
    "japanMaxCity": "市区町村は35文字以内。",
    "minAccNum": "口座番号は4桁以下にはできません。",
    "routingNum": "ルーティング番号を入力してください。",
    "validRoutingNum": "有効なルーティング番号を入力してください。",
    "minRoutingNum": "ルーティング番号は9桁以下にはできません。",
    "ValidRoutingNum": "有効なルーティング番号を入力してください。",
    "Acctype": "アカウントの種類を選択してください。",
    "validAddress": "有効な住所を入力してください。",
    "maxAddress": "住所は400文字を超えることはできません。",
    "minAddress": "住所は5文字以内",
    "City": "都市名を入力してください。",
    "maxCity": "市区町村は60文字を超えることはできません。",
    "countryName": "国名を選択してください",
    "stateName": "有効な州を入力してください。",
    "validzipCode": "有効な郵便番号を入力してください。",
    "selectVerificationType": "検証タイプを選択してください。",
    "debitAmnt": "引き落とし金額を入力してください。",
    "creditAmnt": "クレジット金額を入力してください。",
    "CreditAmount": "クレジット金額を入力してください。",
    "AccName": "アカウント名を入力してください。",
    "recipienttype": "受取人のタイプを選択してください。",
    "accNumber": "口座番号を入力してください。",
    "validAccnum": "有効な口座番号を入力してください。",
    "maxAccNumber": "口座番号は20桁を超えることはできません。",
    "minAccNumber": "口座番号は4桁以下にはできません。",
    "beneficiaryBankcode": "受取銀行コードを入力してください。",
    "minRoutingNumber": "ルーティング番号は9桁以下にはできません。",
    "acountNumber": "口座番号を入力してください。",
    "maxAccnum": "口座番号は20桁を超えることはできません。",
    "minAccnum": "口座番号は4桁以下にはできません。",
    "routingnum": "ルーティング番号を入力してください。",
    "maxRoutingnum": "ルーティング番号は9桁以下にはできません。",
    "acctype": "口座の種類を選択してください。",
    "accountNum": "口座番号を入力してください。",
    "validaccountNum": "有効な口座番号を入力してください。",
    "maxAccountNum": "口座番号は20桁以上は使用できません。",
    "minAccountNum": "口座番号は4桁以下にはできません。",
    "enterRoutingNum": "ルーティング番号を入力してください。",
    "minRouting": "ルーティング番号は9桁以下にはできません。",
    "enterAddressLine1": "住所 1 を入力してください。",
    "maxAddrss": "住所は100文字以上入力できません。",
    "minAddrss": "住所は5文字以下にはできません。",
    "enterCity": "都市名を入力してください",
    "selectCountryName": "国名を選択してください",
    "selectStateName": "州名を選択してください",
    "validZipcode": "郵便番号を入力してください",
    "valdAccnum": "有効な口座番号を入力してください",
    "countryname": "国名を選択してください",
    "statename": "有効な州名を入力してください",
    "selectpaymentMethod": "お支払い方法を選択してください",
    "validPhone": "有効な電話番号を入力してください",
    "inpFname": "お名前を入力してください",
    "inpLname": "姓を入力してください",
    "inpEmail": "Eメールを入力してください",
    "valiemail": "有効なEメールを入力してください",
    "validFname": "有効なファーストネームを入力してください",
    "maxFname": "名字は50文字を超えて入力できません",
    "minFname": "最低3文字必要",
    "validLname": "有効な姓を入力してください",
    "maxLname": "名字は50文字以内とさせていただきます",
    "minLname": "最低3文字必要",
    "phoneNum": "電話番号を入力してください",
    "validPhoneNum": "有効な電話番号を入力してください",
    "maxPhone": "電話番号は10桁を超えることはできません",
    "minPhone": "電話番号は10桁でなければならない",
    "fromAcc": "アカウントから選択してください",
    "selectstartDate": "開始日を選択してください",
    "newPsw": "新しいパスワードの保存",
    "unablePsw": "パスワードの更新ができません",
    "inpOldPsw": "旧パスワードを入力してください",
    "inpNewPsw": "新しいパスワードを入力してください",
    "maxPsw": "パスワードは8文字以上",
    "newPassword": "新しいパスワードを確認してください",
    "result": "結果",
    "buyOrsell": "購入または売却を選択してください",
    "frmAccNum": "口座番号からお選びください",
    "selectaccNum": "口座番号を選択してください",
    "paymentInst": "お支払い方法を選択してください",
    "instType": "楽器の種類を選択してください",
    "insufficientFunds": "資金不足",
    "unablePayment": "支払いを作成できない",
    "selectfundingType": "カードと口座を選択してください",
    "amntTrans": "振込金額を入力してください",
    "counterParty": "取引先を選択してください",
    "purposePayment": "支払い目的を選択してください",
    "toAcc": "アカウントに選択してください",
    "validUrl": "有効なURLを入力してください",
    "all": "すべて",
    "loading": "読み込み中...",
    "transactions": "取引内容",
    "noAccountData": "口座データなし",
    "creditTransfer": "単位移行",
    "noTransactionData": "取引データなし",
    "productCredit": "製品クレジット",
    "refresh": "更新",
    "UWallets": "Uウォレット",
    "UWalletNumber": "Uウォレット番号",
    'CreditID': 'クレジットID',
    "InstrumentType": "楽器の種類",
    "SelectCurrency": "通貨を選択",
    "select": "選択",
    "CardType": "カードの種類",
    "pleaseselecttheCardtype": "カードの種類を選択してください",
    "CardNumber": "カード番号",
    "ExpirationDate": "有効期限",
    "CVV": "CVV",
    "validCardNumber": "有効なカード番号を入力してください",
    "failedAddCard": "カードの追加に失敗しました",
    "cardAddedSuccessfully": "カードの追加に成功",
    "validExpiration": "有効期限を入力してください",
    "validCVV": "有効なCVVコードを入力してください",
    "GoBack": "戻る",
    "Confirmation": "確認",
    "NoPaymentInstrumentData": "決済手段が見つかりません",
    "deactivatePayment": "選択した支払手段を無効にしますか？",
    "accountVerified": "成功 ! アカウント詳細の確認に成功しました!",
    "accountNotVerified": "申し訳ございません！アカウントの詳細が確認できませんでした。",
    "validAmount": "有効な金額を入力してください。",
    "noConnectorFound": "お支払い方法は現在ご利用いただけません。",
    "noCountryFound": "国名リストが見つかりません",
    "wentWrongText": "何かが間違っていた！",
    "noStateFound": "州リストが見つかりません",
    "noteText": "注意：チャレンジ・デポジットを利用した銀行口座の確認には、最大5営業日かかります。",
    "accountalreadyExists": "口座番号はすでに存在する",
    "FeatureType": "フィーチャー・タイプ",
    "validFeatureType": "フィーチャー・タイプを選択してください。",
    "NoPaymentChannel": "支払いチャンネルが見つかりません",
    "bankAddedsuccess": "銀行口座の追加に成功",
    "bankAddedFailed": "銀行口座の追加に失敗しました",
    "limitBreach": "限界突破",
    "withdrawMethodSelect": "出金方法の選択",
    "totalAmount": "総額",
    "decimalpointErr": "小数点以下の桁数は最大7桁まで。",
    "termsandCondition": "利用規約に同意すること。",
    "agreeText": "に同意する。",
    "termsText": "諸条件",
    "minAmountTextAch": "最低引き出し額は",
    "withdrawFeeText1": "出金手数料",
    "withdrawFeeText2": " が適用される。",
    "maxAmountWithdrawTxt1": "最大引き出し額は",
    "maxAmountWithdrawTxt2": " リクエストに応じて。",
    "withdrawSuccess": "撤退成功！",
    "createAnotherPay": "別の支払いを作成する",
    "selectCurrency": "通貨を選択してください",
    "lowPayquickerBalance": "ペイクイッカーの残高が少なすぎて支払いができません。",
    "SelectPaymentMethod": "お支払い方法の選択",
    "availablePayBalance": "利用可能なPayquicker残高",
    "fundsLoadedSuccess": "資金が正常にロードされました！",
    "invalidAccDetails": "無効なアカウント詳細",
    "insufficientAccBalance": "口座残高不足",
    "invalidRecuDate": "無効な定期日を選択した",
    "invalidTONum": "無効な口座番号",
    "invalidTrans": "無効な取引",
    "counterPartyNotFound": "カウンターパーティーが見つかりません",
    "ofacFailed": "OFACチェックに失敗",
    "interSORFail": "SOR間取引の禁止",
    "invalidRoutingNum": "無効なルーティング番号",
    "requestedServiceNotAvail": "現在、要求されたサービスは利用できません",
    "limitExceedGlobal": "グローバルリミットを超過",
    "clientLimitExceed": "クライアントトランザクションの上限を超過",
    "dailyLimitExceed": "1日の取引限度額を超えています",
    "invalidRecdetails": "無効な受取人の詳細",
    "unabletoQuote": "見積もりを取得できません",
    "enterRecipntPhone": "受取人の電話番号を入力してください",
    "enterAccNumber": "受取人の口座番号を入力してください",
    "accountNotMatch": "口座番号が一致しません",
    "recipientAccount": "受取口座",
    "convertedAmount": "変換された金額",
    "paymentSuccess": "お支払いは正常に作成されました！",
    "orderNumber": "注文番号",
    "showing": "表示中",
    "of": "の",
    "refNumber": "参照番号",
    "details": "詳細",
    "NoTransactionsFound": "取引が見つかりません",
    "conntedTOQB": "QuickBooksへの接続に成功",
    "QuoteConvertedAmount": "見積もり換算額合計",
    "QBConnectFailed": "QuickBooksへの接続に失敗しました",
    "conenctingToQB": "QuickBooksに接続しています...",
    "synchronizeFailed": "QuickBooksの同期に失敗しました",
    "synchronizeSuccess": "QuickBooksの同期に成功しました",
    "syncQBText": "QuickBooks Onlineと常に同期",
    "accountConnectedToQB": "QuickBooksに接続済みのアカウント",
    "Password": "パスワード",
    "profileChangesSaved": "プロフィールの変更が保存されました",
    "Timezone": "タイムゾーン",
    "Locale": "所在地",
    "passwordUpdateSuccess": "パスワードの変更が保存されました",
    "passwordReqText": "パスワードは指定された条件に従わなければならない",
    "passwordNotMatch": "入力した2つのパスワードが一致しません",
    "newUserSuccess": "新規ユーザーの作成に成功",
    "addNewUser": "新規ユーザーの追加",
    "ssoReq": "SSOが必要",
    "logoutTxt": "ログアウトに成功",
    "updatePaymentSuccess": "決済方法の更新に成功しました",
    "updatePaymentFailed": "支払方法の更新に失敗しました",
    "noDataFoundFound": "データが見つかりません",
    "submit": "提出する",
    "completed": "完了",
    "failed": "失敗",
    "pending": "保留中",
    "sent": "送信済",
    "SessionExpired": "セッションが期限切れです",
    "Uwallet": "Uウォレット",
    "UWalletfunding": "Uウォレットの資金調達",
    "debit": "デビット",
    "credit": "クレジット",
    "CARD": "カード",
    "BANK": "銀行",
    "active": "活動中",
    "ccintl": "クレジットカード・インターナショナル",
    "amex_dis": "Amex/Discover",
    "amex_card_text": "AMEXカードの追加",
    "nodata": "データがありません",
    "note": "備考",
    "load_fund_via_card": "カードで資金をロードする",
    "load_fund_via_ach": "ACH経由でファンドをロードする",
    "load_fund_via_connector": "コネクター経由でファンドをロードする",
    "p2m_via_connector": "コネクター経由P2M ",
    "creditAdjustment": "クレジット調整",
    "debitAdjustment": "借方調整",
    "startDateText": "開始日",
    "endDateText": "終了日",
    "payIn": "入金",
    "payOut": "出金",
    "checking": "チェック",
    "saving": "保存",
    "verify": "検証",
    "reset": "リセット",
    "today": "今日",
    "a2a_reversal": "A2Aの取り消し",
    "credit_point": "クレジット・ポイント",
    "commission_payout_via_ach": "ACHによるコミッションの支払い",
    "commission_payout_via_uWallet": "U Wallet 経由のコミッション支払い",
    "commission_payout_via_payquicker": "Payquicker経由のコミッション支払い",
    "withdraw_via_ach": "ACHによる出金",
    "withdraw_via_payquicker": "Payquickerで出金",
    "credit_reversal": "クレジットの取り消し",
    "load_fund_via_card_reversal": "カードの取り消しによる資金の入金",
    "uwallet_funding_reversal": "U Wallet 資金の取り消し",
    "processing": "処理",
    "declined": "拒否",
    "onHold": "保留中",
    "scheduled": "予定",
    "cancelled": "中止",
    "beneficiaryName": "受益者名",
    "b_b_code": "銀行コードと支店コード",
    "validBankCode": "有効なコードを入力してください。",
    "minBankCode": "コードは7桁以下にはできない。",
    "load_fund_wallet": "ロード・ファンド・ウォレット",
    "ach_transfer": "ACH送金",
    "mater_visa": "マスターカード/ビザ",
    "go_to_transaction": "取引へ",
    "p2m_reversal": "P2Mリバーサル",
    "deposit_account_list": "預金口座リスト",
    "load_fund_via_ach_reversal": "ACHリバーサルによる資金のロード",
    "load_fund_via_connector_reversal": "コネクタ・リバーサルによるファンドの積み込み",
    "KYC_Failed": "KYC認証の失敗",
    "updateKYCText": "KYCの詳細を更新しますか？",
    "userType": "ユーザータイプ",
    "usertypeErr": "ユーザータイプを選択してください。",
    "recidencyNumberTxt": "居住者番号",
    "validResNum": "居住者番号が必要",
    "validresidencyNum": "有効な居住者番号を入力してください。",
    "flexText": "ペイクイッカー・フレックス",
    "flexTextVietnam": "Thanh toán linh hoạt",
    "withdrawalConfig": "出金設定",
    "withdrawalConfiguration": "引出しの構成",
    "addConfig": "設定の追加",
    "addFreequencyConfig": "周波数設定の追加/編集",
    "ReceivingAccount": "受取口座",
    "deactivateConfig": "選択した設定を無効にしますか？",
    "updateConfigSuccess": "成功！コンフィグ更新成功",
    "updateConfigFailed": "コンフィグの更新に失敗しました",
    "add_card_text": "クレジットカードの追加",
    "UWalletTransfer": "UWalletへの移籍",
    "decimalNotPermit": "小数点以下は使用できない",
    "paybyCard": "カードで支払う",
    "daily": '毎日',
    "weekly": "毎週",
    "monthly": "毎月",
    "biweekly": "隔週",
    "immediate": "即時",
    "percentage": "パーセンテージ",
    "percentError": "有効なパーセンテージを入力してください。",
    "toDistributor": "ディストリビューターへ",
    "distributorId": "ディストリビューターIDを入力してください",
    "invalidDistributor": "有効なディストリビューターIDを入力してください。",
    "distributorNotFound": "ディストリビューターが見つかりません",
    "distributorKYCError": "受取人 ディストリビューター未確認",
    "validIDNum": "有効な番号を入力してください。",
    "swiftCode": "スウィフトコード",
    "validBankName": "有効な銀行名を入力してください",
    "JAPANPAYOUT": "銀行",
    "idNumber": "ID番号",
    "commissionHistory": "コミッションの歴史",
    "identificationType": "識別タイプ",
    "identificationValue": "識別値",
    "valididentificationValue": "有効な識別値を入力してください。",
    "valididentificationType": "識別タイプを選択してください",
    "CITCON WITHDRAWAL": "銀行引き出し",
    "validCityName": "有効な都市名を入力してください",
    "pendingApproval": "承認申請中",
    "purposeCode": "支払の目的",
    "plsSeletpurposeCode": "お支払いの目的をお選びください。",
    "QualifyingCredit": "対象クレジット",
    "tooltipAccountName": "姓と名を含むフルネーム（個人の場合：ジョン・スミス、法人の場合：ABCリミテッド）を入力してください。",
    "tooltipAccountNumber": "銀行口座番号を入力",
    "tooltipBankCode": "支店コードを入力",
    "tooltipBranchCode": "支店コードを入力",
    "tooltipSwiftBic": "日本の銀行コードは4桁がマックスだから余計な文言削除して良いかも",
    "tooltipAddress": "居住地（個人の場合）または登録地（会社の場合）の番地を入力してください",
    "tooltipCity": "居住地（個人の場合）または登録住所（会社の場合）の市町村名を入力してください。",
    "tooltipZipcode": "居住地（個人の場合）または登録住所（会社の場合）の郵便番号/郵便番号を入力してください。",
    "tooltipCountry": "居住地（個人の場合）または登録住所（法人の場合）の国を選択してください。",
    "tooltipDOB": "生年月日を選択してください（個人の場合のみ）",
    "tooltipBankName": "銀行名を入力してください。",
    "bankCode": "銀行コード",
    "branchCode": "支店コード",
    "dob": "生年月日",
    "payee": "受取人",
    "IBAN": "国際銀行",
    "tooltipIBAN": "意味が分からない",
    "payeeTooltip": "氏名または会社名",
    "selct_currency_deposit": "通貨",
    "pc_from_text": "より",
    "pc_to_text": "へ",
    "pc_amount_text": "金額",
    "pc_accountNumber": "口座番号",
    "CNY PAYOUT": "銀行（WP）",
    "accountHolderaddress": "口座名義人の住所",
    "accountHolderName": "口座名義",
    "branchName": "支店名",
    "continue": "続ける",
    "validBranchName": "有効な支店名を入力してください。",
    "SOLU": "銀行引き出し",
    "cardUpdatedSuccessfully": "カードの更新に成功",
    "reason": "理由",
    "failedUpdateCard": "カードの更新に失敗しました",
    "requiredError": "このフィールドは必須です。",
    "kycNotVerified": "KYCは未確認。",
    "VND PAYOUT": "Thanh Toán Về Ngân Hàng",
    "phoneNumberAliWP": "86 XXX XXXX XXXX",
    "phoneTooltip": "受益者の電話番号をご記入ください（例：86 XXX XXXX XXXX）。",
    'emailTooltip': "受取人のEメールアドレスを入力",
    "ALIPAY PAYOUT WP": "アリペイ",
    "enterValidAccNameWithLimit": "有効な受取人名を2文字以上70文字以内で入力してください。",
    "enterValidAccNumWithLimit": "有効な口座番号を4文字以上17文字以内で入力してください。",
    "enterValidRoutingNumWithLimit": "有効なルーティング番号を入力",
    "enterValidAddressWithLimit": "有効な住所を2文字以上70文字以内で入力してください。",
    "enterValidCityWithLimit": "有効な都市名を2文字以上35文字以内で入力してください。",
    "enterValidZipcodeWithLimit": "有効な郵便番号を2文字以上10文字以内で入力してください。",
    "enterValidAccNameWithChinese": "有効な名前を漢字で入力してください。",
    "enterValidBankNameWithChinese": "有効な銀行名を漢字で入力してください。",
    "enterValidBranchNameWithChinese": "有効な支店名を漢字で入力してください。",
    "enterValidSoluAccNum": "有効な数値の口座番号を入力してください",
    "enterValidSoluAccNumWith20Limit": "有効な数値アカウント番号を最大 20 文字で入力します。",
    "enterValidSoluAccNumWith17Limit": "有効な口座番号を半角英数字で17文字以内で入力してください。",
    "enterValidSoluPhoneNumWithLimit": "有効な電話番号を半角英数字で20文字以内で入力してください。",
    "enterValidSoluIDNumWithLimit": "入力 有効な英数字のID番号 最大100文字。",
    "enterValidAlipayPayeeNameWithLimit": "有効な受取人名を2文字以上35文字以内で入力してください。",
    "enterValidAlipayPhoneNumWithLimit": "有効な電話番号を最小5文字から最大34文字で入力してください。",
    "enterValidAlipayEmailWithLimit": "有効なEメールアドレスを最小6文字、最大100文字で入力してください。",
    "enterValidThanhAccNameWithChinese": "有効なアカウント名を1文字以上140文字以内で入力してください。",
    "enterValidThanhAccNumWithLimit": "有効な口座番号を1文字以上30文字以内で入力してください。",
    "enterValidThanhSwiftCodeWithLimit": "有効なスイフトコードを8文字以上11文字以内で入力してください。",
    "enterValidThanhAddressWithLimit": "有効なアドレスを2文字以上35文字以内で入力してください。",
    "enterValidBeneficiaryNameWithLimit": "有効な名前を20文字以内で入力してください。",
    "enterValidTWDAccNumWithLimit": "有効な口座番号を20文字以内で入力してください。",
    "enterValidTWDBranchCodeWithLimit": "有効な支店コードを7文字以内で入力してください。",
    "enterValidMYRAccNameWithLimit": "有効な名前を最小2文字、最大50文字で入力してください。",
    "enterValidMYRAccNumWithLimit": "有効な口座番号を16文字以内で入力してください。",
    "enterValidTHBAccNameWithLimit": "有効な名前を35文字以内で入力してください。",
    "enterValidTHBAccNumWithLimit": "有効な口座番号を12文字以内で入力してください。",
    "enterValidTHBSwiftCodeWithLimit": "有効なスイフトコードを11文字以内で入力してください。",
    "enterValidTHBAddressWithLimit": "有効な住所を最小5文字から最大35文字で入力してください。",
    "enterValidTHBCityWithLimit": "有効な都市を60文字以内で入力してください。",
    "enterValidTHBZipcodeWithLimit": "有効な郵便番号を5文字以上10文字以内で入力してください。",
    "enterValidGBPPayeeNameWithLimit": "有効な名前を3文字以上50文字以内で入力してください。",
    "enterValidGBPIbanWithLimit": "有効なイバンを22文字以内で入力してください。",
    "enterValidGBPBankNameWithLimit": "有効な銀行名を最小1文字、最大50文字で入力してください。",
    "enterValidEURAccNameWithLimit": "有効な名前を1文字以上50文字以内で入力してください。",
    "enterValidEURAccNumWithLimit": "有効な口座番号を1文字以上35文字以内で入力してください。",
    "enterValidEURBankNameWithLimit": "有効な銀行名を2文字以上10文字以内で入力してください。",
    "enterValidJPYBankNameWithLimit": "有効な銀行名を35文字以内で入力してください。",
    "acceptTermsandconditions": "私はガイドに従ってアリペイの口座を設定し、資金を受け取った。",
    "failedDuplicateCard": "カードがすでに存在するため、カードの追加に失敗しました。",
}
