module.exports = {
    "dashboard": "Dashboard ",
    "myAccounts": "My Credits",
    "internationalTransfer": "International Transfer ",
    "fundingType": "Cards & Accounts",
    "deposit": "Purchase Credits",
    "withdraw": "Withdraw ",
    "invoice": "Invoice ",
    "requestMoney": "Request Money ",
    "recipients": "Recipients ",
    "developers": "Developers ",
    "myProfile": "My Profile ",
    "logout": "Log Out ",
    "qucikBooks": "QuickBooks ",
    "profileDetails": "Profile Details",
    "password": "password",
    "users": "Users",
    "qbDownloads": "QB Downloads",
    "transfer": "Transfer",
    "allTransactions": "My Transactions",
    "recurringTrans": "Recurring Transactions",
    "firstName": "First Name",
    "lastName": "Last Name",
    "emailAddress": "Email Address",
    "userRole": "User Role",
    "settings": "Settings",
    "oldPass": "Old Password",
    "newPass": "New Password",
    "confirmPass": "Confirm Password",
    "passwordRequirements": "Password Requirements",
    "reqOne": "At least 8 characters",
    "reqTwo": "At least one lowercase letter",
    "reqThree": "At least one uppercase letter",
    "reqFour": "At least one number",
    "reqFive": "At least one special character",
    "reqSix": "At least one special character",
    "reqSeven": "No spaces",
    "saveChanges": "Save Changes",
    "onlyIntAccounts": "Only International Accounts",
    "availableBalance": "Available Balance",
    "currentBalance": "Current Balance",
    "lastUpdatedDate": "Last Updated Date",
    "channel": "Channel",
    "webhook": "Webhook",
    "recipientName": "Recipient Name",
    "aCHAccountNumber": "ACH Account Number",
    "wireAccountNumber": "Wire Account Number",
    "aPIKey": "API Key",
    "clientName": "Client Name",
    "createdBy": "Created By",
    "transactionID": "Transaction ID",
    "sourceType": "Source Type",
    "accountReference": "Account Reference",
    "routingReference": "Routing Reference",
    "expiryDate": "Expiry Date",
    "recipientEmail": "Recipient Email",
    "creationDate": "Creation Date",
    "startDate": "Start Date",
    "reference": "Reference",
    "transactionType": "Transaction Type",
    "name": "Name",
    "phone": "Phone",
    "role": "Role",
    "complete": "Complete",
    "failure": "Failure",
    "returns": "Returns",
    "count": "Count",
    "accounts": "Accounts",
    "noAccounts": "No Accounts",
    "transactionsType": "Transactions Type",
    "mostRecentTransactions": "Most Recent Transactions",
    "export": "Export",
    "transactionHistory": "Transaction History",
    "transactionReference": "Transaction Reference",
    "parentReference": "Parent Reference",
    "paymentReference": "Payment Reference",
    "amount": "Amount",
    "invoiceNumber": "Invoice Number",
    "feeAmount": "Fee Amount",
    "taxAmount": "Tax Amount",
    "exciseTax": "Excise Tax",
    "salesTax": "Sales Tax",
    "registrationFee": "Registration Fee",
    "serviceFee": "Service Fee",
    "conversionRate": "Conversion Rate",
    "oFACMatch": "OFAC Match",
    "type": "Type",
    "recurringReference": "Recurring Reference",
    "status": "Status",
    "created": "Created",
    "account": "Account",
    "receiver": "Receiver",
    "description": "Description",
    "paymentType": "Payment Type",
    "from": "From",
    "sendVia": "Send Via",
    "recipientEmailAddress": "Recipient Email Address",
    "recipientPhoneNumber": "Recipient Phone Number",
    "recipientAccountNumber": "Recipient Account Number",
    "confirmAccountNumber": "Confirm Account Number",
    "to": "To",
    "currency": "Currency",
    "direction": "Direction",
    "paymentDate": "Payment Date",
    "service": "Service",
    "frequency": "Frequency",
    "endDate": "End Date",
    "numberofTransfers": "Number of Transfers",
    "internalDescription": "Internal Description",
    "accountNumber": "Account Number",
    "fileName": "File Name",
    "recipient_email": "recipient_email",
    "createdDate": "Created Date",
    "dueDate": "Due Date",
    "memo": "Memo",
    "action": "Action",
    "email": "Email",
    "phoneNumber": "Phone Number",
    "bankName": "Bank Name",
    "recipientType": "Recipient Type",
    "fromAccountNumber": "From Account Number",
    "nameonCard": "Name on Card",
    "zipCode": "Zip Code",
    "accountName": "Account Name",
    "routingNumber": "Routing Number",
    "accountType": "Account Type",
    "address": "Address",
    "city": "City",
    "country": "Country",
    "state": "State",
    "verificationType": "Verification Type",
    "debitAmount": "Debit Amount",
    "firstCreditAmount": "First Credit Amount",
    "secondCreditAmount": "Second Credit Amount",
    "nickName": "Nick Name",
    "beneficiaryBankCode": "Beneficiary Bank Code",
    "addressLine1": "Address Line 1",
    "addressLine2": "Address Line 2",
    "paymentMethod": "Payment Method",
    "referenceID": "Reference ID",
    "aCHDetails": "ACH Details",
    "wireDetails": "Wire Details",
    "internationalTransferDetails": "International Transfer Details",
    "selectDate": "Select Date",
    "sender": "Sender",
    "remarks": "Remarks",
    "buyorSell": "Buy or Sell",
    "toAccountNumber": "To Account Number",
    "paymentInstrument": "Payment Instrument",
    "fromaccount": "From account",
    "toCounterparty": "To Counterparty",
    "purposeofPayment": "Purpose of Payment",
    "fXRate": "FX Rate",
    "fXAmount": "FX Amount",
    "fXCurrency": "FX Currency",
    "toName": "To Name",
    "toAccount": "To Account",
    "nextTransactionDate": "Next Transaction Date",
    "aPICode": "API Code",
    "publicKey": "Public Key",
    "privateKey": "Private Key",
    "optional": "optional",
    "additionalInformation": "Additional Information",
    "createPayment": "Create Payment",
    "downloadPDF": "Download PDF",
    "resend": "Resend",
    "approve": "Approve",
    "decline": "Decline",
    "addCard": "Add Card",
    "addBankAccount": "Add Bank Account",
    "addNewRecipient": "Add New Recipient",
    "synchronizeQuickBooks": "Synchronize QuickBooks",
    "create": "Create",
    "newUser": "New User",
    "generate": "Generate",
    "wITHDRAW": "WITHDRAW ",
    "confirm": "Confirm ",
    "cancel": "Cancel",
    "close": "Close ",
    "sendResetInstructions": "Send Reset Instructions ",
    "logIn": "Log In ",
    "resetPassword": "Reset Password ",
    "setPassword": "Set Password ",
    "signUp": "Sign Up",
    "forgotPassword": "Forgot Password",
    "backToLogin": "Back to log in",
    "enterOtp": "Enter Verification Code",
    "resendCode": "Send a new code",
    "selectPayment": "Select Payment Type",
    "scheduleRecurringTransaction": "Schedule Recurring Transaction",
    "forgotPassText": "Enter the email associated with your account and we'll send an email with instructions to reset your password.",
    "unableToCP": "Unable to create payment",
    "plsSelectPaymentTyp": "Please select payment type",
    "plsSelectFromAcc": "Please select from account",
    "selectPaymentPayment": "Please select payment type",
    "enterEmail": "Please enter the Email Address.",
    "emailValidation": "Email Address cannot be more than 320 Characters.",
    "enterPhone": "Please enter a valid phone number",
    "enterAccNum": "Please enter the Account Number",
    "AccNum": "Please enter the Account Number",
    "selectRecipientName": "Please select recipient name",
    "enterAmounttoTransfer": "Please enter the amount to transfer",
    "enterCurrency": "Please enter the currency to transfer",
    "selectDirection": "Please select Direction",
    "selectPaymentDate": "Please select payment date",
    "selectPaymentdate": "Please select payment date",
    "selectServiceType": "Please select service type",
    "selectFrequency": "Please select Frequency",
    "enterAmount": "Please enter the amount",
    "selectTxnDate": "Please select transaction date",
    "selectRecipientType": "Please select recipient type.",
    "enterEmailAddress": "Please enter the Email Address.",
    "validEmail": "Please enter a valid Email Address",
    "EmailValidation": "Email Address cannot be more than 320 Characters.",
    "selectFrmAcc": "Please select from account",
    "invoiceAlpha": "Invoice number should be alpha numeric",
    "enterInvoiceNum": "Please enter the Invoice Number.",
    "invoiceValidation": "Invoice Number cannot be more than 150 Characters.",
    "amountToTransfer": "Please enter the amount to transfer",
    "valueForMemo": "Please enter a value for Memo.",
    "memoValidation": "Memo cannot be more than 255 Characters.",
    "selectPaymentType": "Please select the payment type",
    "enterName": "Please enter the name.",
    "ValidName": "Please enter valid name",
    "nameValidation": "Name cannot be less than 3 characters.",
    "Name": "Please enter the name.",
    "validname": "Please enter valid name",
    "nameValid": "Name cannot be less than 3 characters.",
    "enterZipCode": "Please enter the zip code.",
    "validZipCode": "Please enter valid zip code",
    "validzipcode": "Zip code cannot be less than 5 digits.",
    "validAccName": "Please enter the Account Name.",
    "enterValidAccName": "Please enter a valid Account name",
    "maxAccName": "Account name cannot be more than 20 digits.",
    "minAccName": "Account name cannot be less than 3 digits.",
    "accNum": "Please enter the Account Number.",
    "validAccNum": "Please enter a valid Account Number",
    "maxAccNum": "Account number cannot be more than 20 digits.",
    "japanMaxAccNum": "Account number cannot be more than 35 digits.",
    "japanMaxBankName": "Bank Name cannot be more than 35 digits.",
    "japanMinSwiftCode": "Swift Code should be minimum 8 characters",
    "japanMinAddress": "Address cannot be less than 2 Characters",
    "japanMaxCity": "City cannot be more than 35 Characters.",
    "minAccNum": "Account number cannot be less than 4 digits.",
    "routingNum": "Please enter the Routing Number.",
    "validRoutingNum": "Please enter a valid Routing Number",
    "minRoutingNum": "Routing number cannot be less than 9 digits.",
    "ValidRoutingNum": "Please enter a valid Routing Number.",
    "Acctype": "Please select an Account Type.",
    "validAddress": "Please enter a valid address.",
    "maxAddress": "Address cannot be more than 400 Characters.",
    "minAddress": "Address cannot be less than 5 Characters",
    "City": "Please enter your city.",
    "maxCity": "City cannot be more than 60 Characters.",
    "countryName": "Please Select a Country Name",
    "stateName": "Please enter a valid State",
    "validzipCode": "Please enter a valid Zip Code",
    "selectVerificationType": "Please select an Verification Type.",
    "debitAmnt": "Please enter the debit amount.",
    "creditAmnt": "Please enter the credit amount.",
    "CreditAmount": "Please enter the credit amount.",
    "AccName": "Please enter the Account Name.",
    "recipienttype": "Please select recipient type.",
    "accNumber": "Please enter the Account Number.",
    "validAccnum": "Please enter a valid Account Number",
    "maxAccNumber": "Account number cannot be more than 20 digits.",
    "minAccNumber": "Account number cannot be less than 4 digits.",
    "beneficiaryBankcode": "Please enter the Beneficiary Bank Code.",
    "minRoutingNumber": "Routing number cannot be less than 9 digits.",
    "acountNumber": "Please enter the Account Number.",
    "maxAccnum": "Account number cannot be more than 20 digits.",
    "minAccnum": "Account number cannot be less than 4 digits.",
    "routingnum": "Please enter the Routing Number.",
    "maxRoutingnum": "Routing number cannot be less than 9 digits.",
    "acctype": "Please select an Account Type.",
    "accountNum": "Please enter the Account Number.",
    "validaccountNum": "Please enter a valid Account Number",
    "maxAccountNum": "Account number cannot be more than 20 digits.",
    "minAccountNum": "Account number cannot be less than 4 digits.",
    "enterRoutingNum": "Please enter the Routing Number.",
    "minRouting": "Routing number cannot be less than 9 digits.",
    "enterAddressLine1": "Please enter the Address Line 1.",
    "maxAddrss": "Address cannot be more than 100 Characters.",
    "minAddrss": "Address cannot be less than 5 Characters",
    "enterCity": "Please enter the City",
    "selectCountryName": "Please Select a Country Name.",
    "selectStateName": "Please Select a State Name",
    "validZipcode": "Please enter a valid Zip Code",
    "valdAccnum": "Please enter a valid Account Number",
    "countryname": "Please Select a Country Name.",
    "statename": "Please Enter a Valid State Name",
    "selectpaymentMethod": "Please Select a Payment method.",
    "validPhone": "Please enter a valid phone number",
    "inpFname": "Please input your first name",
    "inpLname": "Please input your last name",
    "inpEmail": "Please input your email",
    "valiemail": "Please enter a valid email",
    "validFname": "Please enter a valid first name",
    "maxFname": "First Name cannot be more than 50 Characters.",
    "minFname": "Minimum 3 characters required.",
    "validLname": "Please enter a valid last name",
    "maxLname": "last Name cannot be more than 50 Characters.",
    "minLname": "Minimum 3 characters required.",
    "phoneNum": "Please enter phone number.",
    "validPhoneNum": "Please enter a valid phone number",
    "maxPhone": "Phone number cannot be more than 10 digits.",
    "minPhone": "Phone number should be 10 digits.",
    "fromAcc": "Please select from account",
    "selectstartDate": "Please select start date",
    "newPsw": "New Password Saved",
    "unablePsw": "Unable to update Password",
    "inpOldPsw": "Please input your old password",
    "inpNewPsw": "Please input your new password",
    "maxPsw": "Password must be longer than 8 characters",
    "newPassword": "Please confirm your new password",
    "result": "result",
    "buyOrsell": "Please select Buy or Sell",
    "frmAccNum": "Please select from account number",
    "selectaccNum": "Please select to account number",
    "paymentInst": "Please select payment instrument",
    "instType": "Please select instrument type",
    "insufficientFunds": "Insufficient funds",
    "unablePayment": "Unable to create payment",
    "selectfundingType": "Please select Cards & Accounts",
    "amntTrans": "Please enter the amount to transfer",
    "counterParty": "Please select counterparty.",
    "purposePayment": "Please select purpose of payment.",
    "toAcc": "Please select to account",
    "validUrl": "Please enter valid Url",
    "all": "All",
    "loading": "Loading...",
    "transactions": "Transaction",
    "noAccountData": "No Account Data",
    "creditTransfer": "Credit Transfer",
    "noTransactionData": "No Transaction Data",
    "productCredit": "Product Credit",
    "refresh": "Refresh",
    "UWallets": "UWallets",
    "UWalletNumber": "UWallet Number",
    'CreditID': 'Credit ID',
    "InstrumentType": "Instrument Type",
    "SelectCurrency": "Select Currency",
    "select": "Select",
    "CardType": "Card Type",
    "pleaseselecttheCardtype": "Please select the Card type",
    "CardNumber": "Card Number",
    "ExpirationDate": "Expiration Date",
    "CVV": "CVV",
    "validCardNumber": "Please enter valid Card Number",
    "failedAddCard": "Failed to add card",
    "cardAddedSuccessfully": "Card added successfully",
    "validExpiration": "Please enter valid Expiration Date",
    "validCVV": "Please enter valid CVV Code",
    "GoBack": "Go Back",
    "Confirmation": "Confirmation",
    "NoPaymentInstrumentData": "No Payment Instrument Found",
    "deactivatePayment": "Do you want to deactivate selected Payment Instrument?",
    "accountVerified": "Success !! Account details verified successfully !!",
    "accountNotVerified": "Sorry !! Account details could not be verified.",
    "validAmount": "Please enter the valid amount",
    "noConnectorFound": "Payment methods not available at this time.",
    "noCountryFound": "No Country List found",
    "wentWrongText": "Something went wrong!",
    "noStateFound": "No State List found",
    "noteText": "Note: Bank Account Verification using Challenge Deposit process will take up to 5 Working days",
    "accountalreadyExists": "Account number already exists",
    "FeatureType": "Feature Type",
    "validFeatureType": "Please select an Feature Type.",
    "NoPaymentChannel": "No Payment Channel Found",
    "bankAddedsuccess": "Bank account added successfully",
    "bankAddedFailed": "Failed to add bank account",
    "limitBreach": "Limit Breach",
    "withdrawMethodSelect": "Select Withdraw Method",
    "totalAmount": "Total Amount",
    "decimalpointErr": "Max 7 digits accepted before decimal point.",
    "termsandCondition": "Should accept terms and conditions.",
    "agreeText": "I agree with the",
    "termsText": "terms and conditions.",
    "minAmountTextAch": "The min withdrawal amount is ",
    "withdrawFeeText1": "A withdrawal fee of ",
    "withdrawFeeText2": " will apply.",
    "maxAmountWithdrawTxt1": "The max withdrawal amount is ",
    "maxAmountWithdrawTxt2": " per request.",
    "withdrawSuccess": "Withdrawal Successful!",
    "createAnotherPay": "Create Another Payment",
    "selectCurrency": "Please select Currency",
    "lowPayquickerBalance": "Your payquicker balance is too low to make a payment",
    "SelectPaymentMethod": "Select Payment Method",
    "availablePayBalance": "Available Payquicker Balance",
    "fundsLoadedSuccess": "Funds loaded Successfully!",
    "invalidAccDetails": "Invalid Account Details",
    "insufficientAccBalance": "Insufficient Account Balance",
    "invalidRecuDate": "Invalid Recurring Date Selected",
    "invalidTONum": "Invalid To Account Number",
    "invalidTrans": "Invalid Transaction",
    "counterPartyNotFound": "CounterParty not found",
    "ofacFailed": "OFAC check failed",
    "interSORFail": "Inter SOR transactions not allowed",
    "invalidRoutingNum": "Invalid routing number",
    "requestedServiceNotAvail": "Currently the requested service is not available",
    "limitExceedGlobal": "Global limit exceeded",
    "clientLimitExceed": "Client transaction limit exceeded",
    "dailyLimitExceed": "Daily transaction limit exceeded",
    "invalidRecdetails": "Invalid recipient details",
    "unabletoQuote": "Unable to get quote",
    "enterRecipntPhone": "Enter Recipient Phone Number",
    "enterAccNumber": "Enter Recipient Account Number",
    "accountNotMatch": "Account Number not matching",
    "recipientAccount": "Recipient Account",
    "convertedAmount": "Converted Amount",
    "paymentSuccess": "Payment Created Successfully!",
    "orderNumber": "Order Number",
    "showing": "Showing",
    "of": "of",
    "refNumber": "Reference Number",
    "details": "Details",
    "NoTransactionsFound": "No Transactions found",
    "conntedTOQB": "Successfully connected to QuickBooks",
    "QuoteConvertedAmount": "Total Quote Converted Amount",
    "QBConnectFailed": "Failed to connect to QuickBooks",
    "conenctingToQB": "Connecting to QuickBooks, Please wait...",
    "synchronizeFailed": "Failed to synchronize QuickBooks",
    "synchronizeSuccess": "Successfully synchronized QuickBooks",
    "syncQBText": "Stay Synced with QuickBooks Online",
    "accountConnectedToQB": "Account already connected to QuickBooks",
    "Password": "Password",
    "profileChangesSaved": "Your profile changes have been saved",
    "Timezone": "Timezone",
    "Locale": "Locale",
    "passwordUpdateSuccess": "Your Password changes have been saved successfully",
    "passwordReqText": "Password must follow specified requirements",
    "passwordNotMatch": "The two passwords that you entered do not match",
    "newUserSuccess": "New user created successfully",
    "addNewUser": "Add New User",
    "ssoReq": "SSO Required",
    "logoutTxt": "Successfully logged out",
    "updatePaymentSuccess": "Success! updated Payment Instrument successfully",
    "updatePaymentFailed": "Failed to update Payment Instrument",
    "noDataFoundFound": "No data found",
    "submit": "Submit",
    "completed": "Completed",
    "failed": "Failed",
    "pending": "Pending",
    "sent": "Sent",
    "SessionExpired": "Session Expired",
    "Uwallet": "Uwallet",
    "UWalletfunding": "UWallet funding",
    "debit": "Debit",
    "credit": "Credit",
    "CARD": "Card",
    "BANK": "Bank",
    "active": "Active",
    "ccintl": "Credit Card International",
    "amex_dis": "Amex/Discover",
    "amex_card_text": "Add AMEX Card",
    "nodata": "No data",
    "note": "Note",
    "load_fund_via_card": "Load fund via Card",
    "load_fund_via_ach": "Load fund via ACH",
    "load_fund_via_connector": "Load fund via connector",
    "p2m_via_connector": "P2M via connector ",
    "creditAdjustment": "Credit Adjustment",
    "debitAdjustment": "Debit Adjustment",
    "startDateText": "Start Date",
    "endDateText": "End Date",
    "payIn": "Payin",
    "payOut": "Payout",
    "checking": "Checking",
    "saving": "Saving",
    "verify": "Verify",
    "reset": "Reset",
    "today": "Today",
    "a2a_reversal": "A2A Reversal",
    "credit_point": "Credit Points",
    "commission_payout_via_ach": "Commission Payout via ACH",
    "commission_payout_via_uWallet": "Commission Payout via UWallet",
    "commission_payout_via_payquicker": "Commission Payout via Payquicker",
    "withdraw_via_ach": "Withdraw funds via ACH",
    "withdraw_via_payquicker": "Withdraw funds via Payquicker",
    "credit_reversal": "Credit Reversal",
    "load_fund_via_card_reversal": "Load fund via card reversal",
    "uwallet_funding_reversal": "U Wallet funding reversal",
    "processing": "Processing",
    "declined": "Declined",
    "onHold": "On Hold",
    "scheduled": "Scheduled",
    "cancelled": "Cancelled",
    "beneficiaryName": "Beneficiary Name",
    "b_b_code": "Bank Code & Branch Code",
    "validBankCode": "Please enter a valid code",
    "minBankCode": "Code cannot be less than 7 digits.",
    "load_fund_wallet": "Load funds wallet",
    "ach_transfer": "ACH Transfer",
    "mater_visa": "MasterCard/Visa",
    "go_to_transaction": "Go to transactions",
    "p2m_reversal": "P2M Reversal",
    "deposit_account_list": "Deposit Account List",
    "load_fund_via_ach_reversal": "Load funds via ACH reversal",
    "load_fund_via_connector_reversal": "Load funds via Connector reversal",
    "KYC_Failed": "KYC Verification Failed",
    "updateKYCText": "Do you want to update KYC Details?",
    "userType": "User Type",
    "usertypeErr": "Please select an User Type.",
    "recidencyNumberTxt": "Residency Number",
    "validResNum": "Residency Number Required",
    "validresidencyNum": "Please enter valid residency number",
    "flexText": "PayQuicker Flex",
    "flexTextVietnam": "Thanh toán linh hoạt",
    "withdrawalConfig": "Withdrawal Config",
    "withdrawalConfiguration": "Withdrawal Configuration",
    "addConfig": "Add Config",
    "addFreequencyConfig": "Add/Edit Frequency Config",
    "ReceivingAccount": "Receiving Account",
    "deactivateConfig": "Do you want to deactivate selected Configuration?",
    "updateConfigSuccess": "Success! updated Config successfully",
    "updateConfigFailed": "Failed to update Config",
    "add_card_text": "Add Credit Card",
    "UWalletTransfer": "UWallet Transfer",
    "decimalNotPermit": "Decimal places not allowed",
    "paybyCard": "Pay By Card",
    "daily": 'Daily',
    "weekly": "Weekly",
    "monthly": "Monthly",
    "biweekly": "Bi-Weekly",
    "immediate": "Immediate",
    "percentage": "Percentage",
    "percentError": "Please enter valid percentage",
    "toDistributor": "To Distributor",
    "distributorId": "Please enter Distributor ID",
    "invalidDistributor": "Please enter valid Distributor Id",
    "distributorNotFound": "Distributor not found",
    "distributorKYCError": "Recipient Distributor not verified",
    "validIDNum": "Please enter valid number",
    "swiftCode": "Swift Code",
    "validBankName": "Please enter valid bank name",
    "JAPANPAYOUT": "Bank",
    "idNumber": "ID Number",
    "commissionHistory": "Commission History",
    "identificationType": "Identification Type",
    "identificationValue": "Identification Value",
    "valididentificationValue": "Please enter valid identification value",
    "valididentificationType": "Please select identification type",
    "CITCON WITHDRAWAL": "Bank Withdrawal",
    "validCityName": "Please enter valid city name",
    "pendingApproval": "Pending Approval",
    "purposeCode": "Payment Purpose",
    "plsSeletpurposeCode": "Please select purpose of payment",
    "QualifyingCredit": "Qualifying Credit",
    "tooltipAccountName": "Please enter your full name, including first and last name (if an individual) e.g John Smith OR your full legal company name (if a company) e.g ABC Limited",
    "tooltipAccountNumber": "Enter the bank account number",
    "tooltipBankCode": "Enter the bank code",
    "tooltipBranchCode": "Enter the branch code",
    "tooltipSwiftBic": "Please enter your bank's SWIFT BIC (Bank Identity Code) which can either be 8 or 11 characters in length.  If the SWIFT BIC is only 8 characters, do NOT infill to 11 characters by adding XXX",
    "tooltipAddress": "Please enter your residential (if an individual) or registered (if a company) house number/name and street name e.g 1 Main Street",
    "tooltipCity": "Please enter city or town of your residential (if an individual) or registered (if a company) address e.g London",
    "tooltipZipcode": "Please enter postal/zip code of your residential (if an individual) or registered (if a company) address",
    "tooltipCountry": "Please select the country of your residential (if an individual) or registered (if a company) address",
    "tooltipDOB": "Please select your date of birth (if an individual only)",
    "tooltipBankName": "Please enter the name of your bank",
    "bankCode": "Bank Code",
    "branchCode": "Branch Code",
    "dob": "Date of birth",
    "payee": "Payee",
    "IBAN": "IBAN",
    "tooltipIBAN": "GBxx xxxx xxxx xxxx xxxx xx",
    "payeeTooltip": "Your full name or legal company name",
    "selct_currency_deposit": "Currency",
    "pc_from_text": "From",
    "pc_to_text": "To",
    "pc_amount_text": "Amount",
    "pc_accountNumber": "Account Number",
    "CNY PAYOUT": "Bank (WP)",
    "accountHolderaddress": "Account Holder's Address",
    "accountHolderName": "Account Holder's Name",
    "branchName": "Branch Name",
    "continue": "Continue",
    "validBranchName": "Please enter valid branch name",
    "SOLU": "Bank Withdrawal",
    "cardUpdatedSuccessfully": "Card updated successfully",
    "reason": "Reason",
    "failedUpdateCard": "Failed to update card",
    "requiredError": "This field is required",
    "kycNotVerified": "KYC not verified.",
    "VND PAYOUT": "Thanh Toán Về Ngân Hàng",
    "phoneNumberAliWP": "86 XXX XXXX XXXX",
    "phoneTooltip": "Please provide the beneficiary''s phone number (example: 86 XXX XXXX XXXX)",
    'emailTooltip': "Enter the beneficiary Email Address ",
    "ALIPAY PAYOUT WP": "Alipay",

    "enterValidAccNameWithLimit": "Enter Valid Payee name with minimum 2 and maximum 70 characters",
    "enterValidAccNumWithLimit": "Enter Valid account number with minimum 4 and maximum 17 characters",
    "enterValidRoutingNumWithLimit": "Enter Valid routing number",
    "enterValidAddressWithLimit": "Enter Valid Address with minimum 2 and maximum 70 characters",
    "enterValidCityWithLimit": "Enter Valid City with minimum 2 and maximum 35 characters",
    "enterValidZipcodeWithLimit": "Enter Valid Zip code with minimum 2 and maximum 10 characters",

    "enterValidAccNameWithChinese": "Enter valid name in Chinese Characters",
    "enterValidBankNameWithChinese": "Enter valid Bank name in Chinese Characters",
    "enterValidBranchNameWithChinese": "Enter valid Branch name in Chinese Characters",
    "enterValidSoluAccNum": "Enter Valid numeric account number",
    "enterValidSoluAccNumWith20Limit": "Enter Valid numeric account number maximum 20 characters.",
    "enterValidSoluAccNumWith17Limit": "Enter Valid numeric account number maximum 17 characters.",
    "enterValidSoluPhoneNumWithLimit": "Enter Valid numeric phone number maximum 20 characters.",
    "enterValidSoluIDNumWithLimit": "Enter Valid alphanumeric ID number maximum 100 characters.",

    "enterValidAlipayPayeeNameWithLimit": "Enter Valid Payee name with minimum 2 and maximum 35 characters",
    "enterValidAlipayPhoneNumWithLimit": "Enter Valid numeric phone number with minimum = 5 maximum 34 characters.",
    "enterValidAlipayEmailWithLimit": "Enter Valid Email address  with minimum = 6 maximum 100 characters.",

    "enterValidThanhAccNameWithChinese": "Enter Valid  account name with minimum 1 and maximum 140 characters.",
    "enterValidThanhAccNumWithLimit": "Enter Valid  account number  with minimum 1 and maximum 30 characters.",
    "enterValidThanhSwiftCodeWithLimit": "Enter Valid swift code with minimum 8 and maximum 11 characters.",
    "enterValidThanhAddressWithLimit": "Enter Valid  address  with minimum 2 and maximum 35 characters.",

    "enterValidBeneficiaryNameWithLimit": "Enter valid name with maximum 20 characters.",
    "enterValidTWDAccNumWithLimit": "Enter valid account number with maximum 20 characters.",
    "enterValidTWDBranchCodeWithLimit": "Enter valid branch code with maximum 7 characters.",

    "enterValidMYRAccNameWithLimit": "Enter valid name with minimum = 2 and maximum 50 characters.",
    "enterValidMYRAccNumWithLimit": "Enter valid numeric account number with maximum 16 characters.",

    "enterValidTHBAccNameWithLimit": "Enter valid name with maximum 35 characters.",
    "enterValidTHBAccNumWithLimit": "Enter valid numeric account number  with  maximum 12 characters.",
    "enterValidTHBSwiftCodeWithLimit": "Enter valid swift code with maximum 11 characters.",
    "enterValidTHBAddressWithLimit": "Enter valid Address with minimum = 5 and maximum 35 characters.",
    "enterValidTHBCityWithLimit": "Enter valid city with maximum 60 characters.",
    "enterValidTHBZipcodeWithLimit": "Enter valid zip code with minimum = 5 and maximum 10 characters.",

    "enterValidGBPPayeeNameWithLimit": "Enter valid name with Minimum 3 and maximum 50 characters.",
    "enterValidGBPIbanWithLimit": "Enter valid Iban with maximum 22 characters.",
    "enterValidGBPBankNameWithLimit": "Enter valid Bank name with Minimum = 1 and maximum 50 characters.",

    "enterValidEURAccNameWithLimit": "Enter valid name with Minimum 1 and maximum 50 characters.",
    "enterValidEURAccNumWithLimit": "Enter valid numeric account number  with  Minimum 1 and maximum 35 characters.",
    "enterValidEURBankNameWithLimit": "Enter valid Bank name with Minimum = 2 and maximum 10 characters.",

    "enterValidJPYBankNameWithLimit": "Enter valid bank name with  maximum 35 characters.",
    "acceptTermsandconditions": "I followed the guide and set up my Alipay account to receive funds.",
    "failedDuplicateCard": "Card addition failed as card already exist.",
}
